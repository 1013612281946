import { useEffect, useRef, useState } from 'react';
import ApiService from "../../services/api.service";
import { useForm, FormProvider } from 'react-hook-form';
import { Input, Select } from '../../components/forms/add/Components';

import HelperService from '../../services/helper.service';
import { NavLink } from 'react-router-dom';


const Register = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const activationhash = queryParameters.get("token");

    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(null);

    if (error) {
        throw error;
    }


    const overlayRef = useRef(null);
    const [openOverlay, setOpenOverlay] = useState(false);
    const [overlayContent, setOverlayContent] = useState();


    const methods = useForm();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [submitted, setSubmitted] = useState(false);
    const [hashChecked, setHashChecked] = useState(false);

    const [registrationSend, setRegistrationSend] = useState(false);

    useEffect(() => {
        if (activationhash) {
            const API_URL = global.config.express.url + '/user/checkregistrationhash';
            const API_DATA = {
                'token': activationhash
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    setHashChecked(true);
                }
            }).catch((error) => { setError(error) });
        }
    }, [activationhash]);


    const onSubmit = (data) => {
        setSubmitted(true);
        const API_URL = global.config.express.url + '/user/register';
        data.redirectUrl = window.location.origin + window.location.pathname;
        const API_DATA = data;
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                if (response.success) {
                    setRegistrationSend(true);
                }
                else {
                    if (response.formerror) {
                        setFormError(response.formerror);
                        setSubmitted(false);
                    }
                }
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });
    }


    const openContent = (section) => {

        const API_URL = global.config.express.url + '/global/content';
        const API_DATA = {
            section: section
        };
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                if (response) {
                    setOverlayContent(response);
                    setOpenOverlay(true);
                }
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });

    }


    let cont =
        <>
            <div className='form register'>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-add" onKeyDown={(e) => { HelperService.handleFormKeyDown(e) }}>
                        <h2>Registrierung</h2>
                        <Select
                            register={register}
                            options={{
                                '': 'bitte auswählen',
                                'male': 'Herr',
                                'female': 'Frau',
                                'divers': 'keine Angabe'
                            }}
                            name="gender"
                            label="Anrede"
                            size=""
                            required
                            errors={errors}
                        />
                        <Input register={register} type="text" name="firstname" label="Vorname" size="" required errors={errors}></Input>
                        <Input register={register} type="text" name="lastname" label="Nachname" size="" required errors={errors}></Input>
                        <Input register={register} type="text" name="email" label="E-Mail-Adresse" size="" required errors={errors} pattern="email"></Input>
                        <Input register={register} type="password" name="password" label="Passwort" size="" errors={errors} required pattern="password"></Input>

                        <p className="terms">Bitte lesen Sie unsere <span className='linkbutton' onClick={() => openContent('termscondition')}>AGB</span> und <span className='linkbutton' onClick={() => openContent('privacyterms')}>Datenschutzhinweise</span>.</p>

                        <div className="submit_container">
                            <div className="formfieldholder">
                                <button type="submit" className={"submit" + (submitted ? ' load' : '')} disabled={submitted}>registrieren</button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className='options'>
                <div className='smalltext last'>
                    <NavLink to="/">Zum Login</NavLink>
                </div>
            </div>
        </>

    if (formError) {
        cont =
            <>
                <div className='form register'>
                    <form className="form-add">
                        <h2>Registrierung fehlgeschlagen</h2>
                        <p>{formError}</p>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext last'>
                        <NavLink to="/">Zum Login</NavLink>
                    </div>
                </div>
            </>
    }

    if (activationhash && hashChecked) {
        cont =
            <>
                <div className='form register'>
                    <form className="form-add">
                        <h2>Registrierung abgeschlossen</h2>
                        <p>Ab sofort können Sie sich jederzeit mit Ihren Zugangsdaten anmelden.</p>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext last'>
                        <NavLink to="/">Zum Login</NavLink>
                    </div>
                </div>
            </>
    }

    if (registrationSend) {
        cont =
            <div className='form register'>
                <form className="form-add">
                    <h2>Nur noch ein Schritt</h2>
                    <p>Um die Registrierung abzuschließen, klicken Sie bitte auf den Link in der soeben an Sie verschickten E-Mail.</p>
                </form>
            </div>
    }

    return (
        <div className="fe-wrapper">
            <div className='box'>
                <span className='logo'></span>
                {cont}
            </div >
            {
                openOverlay ?
                    <div className="overlay t-center">
                        <div className="overlayholder" ref={overlayRef}>
                            <div className="overlaycontholder" dangerouslySetInnerHTML={{ __html: overlayContent }}></div>
                            <button type="button" className='close' onClick={() => setOpenOverlay(false)}>x</button>
                        </div>
                    </div>
                    : ''
            }
        </div >
    )
}

export default Register
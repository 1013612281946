import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

const AdminSidebar = () => {

  const navstate = useSelector(state => state.global.navstate);

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
            <li><NavLink to="/vermieter"><PeopleAltIcon className='icon' /><span>Vermieterverwaltung</span></NavLink></li>
          </ul>
          <p className='title'>Konfiguration</p>
          <ul>
            <li><NavLink to="/objektarten"><HolidayVillageIcon className='icon' /><span>Objektarten</span></NavLink></li>
            <li><NavLink to="/lagebeschreibungen"><EditLocationAltIcon className='icon' /><span>Lagebeschreibungen</span></NavLink></li>
            <li><NavLink to="/ausstattungsmerkmale"><AppRegistrationIcon className='icon' /><span>Ausstattungsmerkmale</span></NavLink></li>
            <li><NavLink to="/nebenkostenoptionen"><PaymentIcon className='icon' /><span>Nebenkostenoptionen</span></NavLink></li>
            <li><NavLink to="/zusatzkostenoptionen"><AddCardIcon className='icon' /><span>Zusatzkostenoptionen</span></NavLink></li>
            <li><NavLink to="/abrechnungsoptionen"><PaymentsIcon className='icon' /><span>Abrechnungsoptionen</span></NavLink></li>
            <li><NavLink to="/globale-texte"><FormatAlignLeftIcon className='icon' /><span>Globale Texte</span></NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AdminSidebar 